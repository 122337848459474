<template>
  <div class="container">
    <div class="education-field-form mt-4">
      <h4 class="text-center">Область образования</h4>
      
      <!-- Delete Education Field Modal -->
      <div class="modal fade" id="deleteEducationFieldModal" tabindex="-1"
           aria-labelledby="deleteEducationFieldModalLabel"
           aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteEducationFieldModalLabel">Удаление области
                образования</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mt-4">
                <p>Вы точно хотите удалить область образования?</p>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                      @click="deleteEducationField(deleteEducationFieldId)">
                Удалить
              </button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Отменить
              </button>

            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->

      <div class="education-field-table">

        <div class="row mt-4">
          <div class="col-md-1">№</div>
          <div class="col-md-9">Название</div>
          <div class="col-md-1">Редактировать</div>
          <div class="col-md-1">Удалить</div>
        </div>

        <hr>
        <div class="row" v-for="(item, index) in educationfield_form.educationFieldInfos" :key="index">

          <div class="col-md-1">
            {{ index+1 }}
          </div>
          <div class="col-md-9">
            <input class="form-control" :id="'education_field_'+item.id"
                   placeholder="Название области образования" :value="item.name"
                   @input="changeEducationFieldData(item, 'name', $event)"
                   :disabled="typeof item.edit_status=== 'undefined'"/>
          </div>
          <div class="col-md-1">
            <button v-if="typeof item.edit_status=== 'undefined'" type="button" class="btn btn-warning"
                    @click="editEducationField(index)">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>

            <button v-if="item.edit_status == 1" type="button" class="btn btn-success"
                    @click="updateEducationField(item.id, index)">
              <i class="fas fa-check" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-1">
            <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                    data-bs-target="#deleteEducationFieldModal"
                    @click="openDeleteEducationFieldModal(item.id)">
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </button>
          </div>

          <hr>

        </div>
      </div>

      <nav v-if="pageCount" class="d-flex justify-content-center" aria-label="Page navigation example">
        <Paginate
            v-model="page"
            :page-count="pageCount"
            :click-handler="paginateHandler"
            :prev-text="'Предыдущий'"
            :next-text="'Следующий'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </Paginate>
      </nav>

      <div class="form-row" v-for="(newEducationFieldInfo, index) in educationfield_form.newEducationFieldInfos"
           :key="index">
        <div class="education-field-remove-item__button d-flex flex-row-reverse">
          <i class="fa fa-times" @click="deleteRowEducationFieldInfo(index)"></i>
        </div>


        <div class="form-group row mt-4">
          <label for="education_field_name" class="col-md-3 col-form-label">Название области
            образования</label>
          <div class="col-md-9">
            <input id="education_field_name" type="text" class="form-control"
                   @input="changeNewEducationFieldData(newEducationFieldInfo, 'name', $event)">
          </div>

        </div>
        <div class="form-group row mt-4">
          <div class="col-md-12 text-center">
            <button class="btn btn-pill text-white btn-block btn-primary" v-on:click="submitEducationField">
              Сохранить
            </button>
          </div>
        </div>


        <hr>

      </div>
      <div class="form-group">
        <button @click="addRowEducationFieldInfo" type="button" class="btn btn-secondary"><i
            class="fas fa-plus-circle"></i>
          Добавить
        </button>
      </div>
    </div>


  </div>
</template>

<script>
  import Paginate from '../common/Paginate.vue'
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

  export default {
    name: "EducationField",
    components: {
      Paginate
    },

    data() {
      return {
        deleteEducationFieldId: '',
        page: +this.$route.query.page || 1,
      }
    },
    computed: {
      ...mapState('educationfield', ['educationfield_form', 'pageCount']),
    },

    methods: {
      ...mapActions('educationfield', ['POST_EDUCATION_FIELD_DATA', 'PUT_EDUCATION_FIELD_DATA', 'GET_EDUCATION_FIELD_DATA', 'DELETE_EDUCATION_FIELD_DATA']),
      ...mapMutations('educationfield', ['UPDATE_EDUCATION_FIELD_DATA', 'UPDATE_NEW_EDUCATION_FIELD_DATA',
        'DELETE_ROW_EDUCATION_FIELD_INFO', 'ADD_ROW_EDUCATION_FIELD_INFO', 'CHANGE_EDIT_STATUS_EDUCATION_FIELD_INFO']),

      async paginateHandler(page) {
        this.page = page
        await this.changePage()
      },
      async changePage() {
        this.$router.push(`${this.$route.path}?page=${this.page}`)
        await this.GET_EDUCATION_FIELD_DATA(this.page)
      },
      async deleteEducationField(id) {
        await this.DELETE_EDUCATION_FIELD_DATA(id);
        this.$message({title: 'Удаление', text: 'Успешное удаление'})
        await this.GET_EDUCATION_FIELD_DATA();
      },
      async editEducationField(index) {
        this.CHANGE_EDIT_STATUS_EDUCATION_FIELD_INFO(index)

        //this.editFileId = file_id;
        //console.log(this.editFileId, "EDIT FILE ID")
      },
      async openDeleteEducationFieldModal(id) {
        this.deleteEducationFieldId = id;
        console.log(this.deleteEducationFieldId, "DELETE FILE ID")
      },
      async updateEducationField(id, index) {
        console.log(id, 'KSGSGSG')
        delete this.educationfield_form.educationFieldInfos[index].edit_status;
        await this.PUT_EDUCATION_FIELD_DATA(id)
      },

      deleteRowEducationFieldInfo(index) {
        // if (this.educationfield_form.educationFieldInfos[index].id) {
        //   this.DELETE_EDUCATION_FIELD_DATA(index);
        // }
        this.DELETE_ROW_EDUCATION_FIELD_INFO(index);
      },
      addRowEducationFieldInfo() {
        this.ADD_ROW_EDUCATION_FIELD_INFO();
      },
      submitEducationField() {

        this.POST_EDUCATION_FIELD_DATA().then(res => {
          if (res.success == true) {
            this.GET_EDUCATION_FIELD_DATA();
            this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
          } else {
            let errorText = '';
            for (let err of res.errors[0]) {
              errorText += err.message + '\n'
            }
            this.$error({title: 'Добавление', text: errorText})
          }
        })
      },
      changeEducationFieldData(item, property, e, val = 'value') {
        const value = e.target[val]
        console.log(value, "value")
        this.UPDATE_EDUCATION_FIELD_DATA({item, property, value})
      },
      changeNewEducationFieldData(item, property, e, val = 'value') {
        const value = e.target[val]
        console.log(value, "value")
        this.UPDATE_NEW_EDUCATION_FIELD_DATA({item, property, value})
      },
    },
    created() {
      this.GET_EDUCATION_FIELD_DATA();
    }
  }
</script>

<style scoped>

</style>